import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { FuseAlertModule } from '../../../../@fuse/components/alert';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { ColorPickerComponent } from '@shared/components/color-picker/color-picker.component';

@NgModule({
    imports: [SharedModule, FuseAlertModule, ColorPickerModule],
    providers: [],
    declarations: [ColorPickerComponent],
    exports: [ColorPickerComponent]
})
export class AppColorPickerModule {}
