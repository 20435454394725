<button (click)="$event.preventDefault(); $event.stopPropagation(); togglePanel()" #colorPickerOrigin class="flex items-center">
    <ng-content></ng-content>
</button>

<!-- Shortcuts panel -->
<ng-template #colorPickerPanel>
    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:rounded-md overflow-hidden shadow-lg">
        <!-- Header -->
        <!--        <div class="flex shrink-0-0 items-center py-2 pr-4 pl-6 bg-accent text-on-accent">-->
        <!--            <div class="text-lg font-medium leading-10">Scan QR/BAR code</div>-->
        <!--            <div class="ml-auto">-->
        <!--                <button-->
        <!--                    mat-icon-button-->
        <!--                    (click)="closePanel()">-->
        <!--                    <mat-icon-->
        <!--                        class="icon-size-5 text-current"-->
        <!--                        [svgIcon]="'heroicons_solid:x'"></mat-icon>-->
        <!--                </button>-->
        <!--            </div>-->
        <!--        </div>-->

        <div class="relative flex flex-col flex-auto -mb-px overflow-y-auto bg-card max-w-screen-sm sm:max-w-100">
            <compact-picker [control]="compactControl"></compact-picker>

            <button mat-flat-button [color]="'primary'" class="m-2" (click)="submit()">
                <span class="mx-2">Submit</span>
            </button>
        </div>
    </div>
</ng-template>
