import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LetDirective, PushPipe } from '@ngrx/component';
import { SharedModule } from '@shared/shared.module';
import { CommonTagsComponent } from './tags.component';
import { AppColorPickerModule } from '@shared/components/color-picker/color-picker.module';

@NgModule({
    declarations: [CommonTagsComponent],
    imports: [SharedModule, CommonModule, LetDirective, PushPipe, AppColorPickerModule],
    exports: [CommonTagsComponent]
})
export class TagsModule {}
