import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { ColorPickerControl } from '@iplab/ngx-color-picker';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ColorPickerComponent implements OnInit, OnDestroy {
    @Input() color: string;

    @Output() public colorChanged = new Subject<string>();

    @ViewChild('colorPickerOrigin') private _colorPickerOrigin: ElementRef;
    @ViewChild('colorPickerPanel') private _colorPickerPanel: TemplateRef<any>;

    public compactControl = new ColorPickerControl();

    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef
    ) {}

    init(): void {
        this.compactControl.setValueFrom(this.color);
    }

    ngOnInit(): void {}

    submit(): void {
        this.colorChanged.next(this.compactControl.value.toHexString());
        this.closePanel();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    togglePanel(): void {
        if (this._overlayRef && this._overlayRef.hasAttached()) {
            this.closePanel();
        } else {
            this.openPanel();
        }
    }

    openPanel(): void {
        // Return if the messages panel or its origin is not defined
        if (!this._colorPickerPanel || !this._colorPickerOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._colorPickerPanel, this._viewContainerRef));

        this.init();
    }

    closePanel(): void {
        this._overlayRef.detach();
    }

    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(this._colorPickerOrigin.nativeElement)
                .withLockedPosition()
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    private _buildForm(): void {}
}
