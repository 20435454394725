<mat-form-field subscriptSizing="dynamic" class="mat-field-auto-height max-w-full">
    <mat-chip-grid #chipList>
        <mat-chip-row
            *ngFor="let tag of tags"
            (removed)="remove(tag)"
            [removable]="true"
            (click)="goToTag(tag)"
            [colored]="tag.color"
            class="tag-chip py-1 flex items-center space-x-0.5 max-w-full"
        >
            <span class="text-sm">
                {{ tag.name }}
            </span>

            <div class="tag-actions">
                <app-color-picker *ngIf="updateFunction" [color]="tag.color" (colorChanged)="colorChanged(tag, $event)">
                    <mat-icon class="icon-size-4" svgIcon="mat_outline:palette"></mat-icon>
                </app-color-picker>

                <mat-icon class="icon-size-4 ml-0" matChipRemove [svgIcon]="'heroicons_solid:minus-circle'"></mat-icon>
            </div>
        </mat-chip-row>
        <input
            #tagInput
            placeholder="Add tag..."
            [formControl]="tagCtrl"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="[13, 188, 32]"
            [matAutocomplete]="auto"
            (matChipInputTokenEnd)="entered($event)"
        />
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let tag of filteredTags | async" [value]="tag" class="text-sm">
                {{ tag.name }}
            </mat-option>
        </mat-autocomplete>
    </mat-chip-grid>
</mat-form-field>
